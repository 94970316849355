<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>
            {{ title }}
         </v-card-title>
         <v-card-text>
            <form>
               <v-text-field
                  @input="
                     () => {
                        input();
                        $v.nombre.$touch();
                     }
                  "
                  v-model="nombre"
                  label="Nombre"
                  :error-messages="nombreErrors"
                  required
                  @blur="$v.nombre.$touch()"
               ></v-text-field>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(50),
      },
   },
   props: {
      value: Object,
      title: String,
   },
   model: {
      prop: "value",
      event: "input",
   },
   data: function () {
      return {
         id_vendedor: this.value.id_vendedor,
         nombre: this.value.nombre,
         dialog: false,
         isloading: false,
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertSeller", {
                     nombre: this.nombre,
                  });
               } else {
                  res = await this.$store.dispatch("updateSeller", {
                     id: this.id_vendedor,
                     nombre: this.nombre,
                  });
               }
               if (res == true) {
                  this.$refs.ok_noti.Show();
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.id_vendedor = i.id_vendedor;
         this.nombre = i.nombre;
         this.dialog = true;
      },
      input: function (e) {
         this.$emit("input", {
            id_vendedor: this.id_vendedor,
            nombre: this.nombre,
         });
      },
   },
   computed: {
      isEdit() {
         if (this.id_vendedor) {
            return true;
         } else {
            return false;
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 50 caracteres");
         return errors;
      },
   },
};
</script>
