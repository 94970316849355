<template>
   <Sellers/>
</template>

<script>
import Sellers from '../components/Vendedores/Sellers.vue'

export default {
   name: "Vendedores",
   components:{
      Sellers
   }
};
</script>
