<template>
   <div>
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Seller v-model="editedItem" :title="title" ref="seller" @save="save" @close="close" />
      <Dialog message="¿Eliminar vendedor?" ref="delete" @result_ok="deleteItemConfirm" />
      <v-data-table :headers="headers" :items="rows" class="ma-3">
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn color="primary" @click="$refs.seller.show(editedItem)"><v-icon left>mdi-plus-circle</v-icon>Nuevo vendedor</v-btn>
               <v-btn color="accent" @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
            </v-toolbar>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Seller from "./Sellers/Seller.vue";
import Dialog from "../Dialog.vue";
import Notification from "../Notification.vue";

export default {
   components: {
      Seller,
      Dialog,
      Notification,
   },
   data: () => ({
      headers: [
         { text: "ID", value: "id_vendedor" },
         { text: "Nombre", value: "nombre" },
         { text: "Actions", value: "actions", sortable: false },
      ],
      rows: [],
      editedIndex: -1,
      editedItem: {
         id_vendedor: null,
         nombre: "",
      },
      defaultItem: {
         id_vendedor: null,
         nombre: "",
      },
   }),
   computed: {
      title() {
         return this.editedIndex === -1 ? "Nuevo vendedor" : "Editar vendedor";
      },
   },
   created() {
      this.initialize();
   },
   methods: {
      async initialize() {
         this.rows = this.$store.state.sellers;
      },
      editItem(item) {
         this.editedIndex = this.rows.indexOf(item);
         this.editedItem = Object.assign({}, item);
         this.$refs.seller.show(this.editedItem);
      },
      deleteItem(item) {
         this.editedIndex = this.rows.indexOf(item);
         this.editedItem = Object.assign({}, item);
         this.$refs.delete.Show();
      },
      async deleteItemConfirm() {
         try {
            let res = await this.$store.dispatch("deleteSeller", { id: this.editedItem.id_vendedor });
            if (res == true) {
               this.rows.splice(this.editedIndex, 1);
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.close();
         }
      },
      close() {
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
         });
      },
      save() {
         if (this.editedIndex > -1) {
            Object.assign(this.rows[this.editedIndex], this.editedItem);
         } else {
            this.rows.push(this.editedItem);
         }
         this.initialize();
         this.close();
      },
   },
};
</script>
